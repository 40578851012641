import { Elm } from './Main.elm';

var app = Elm.Main.init({
  node: document.getElementById('root')
});

app.ports.getRandomSelection.subscribe(function (url) {
  sendWordsToElm(url);
})

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

function getRandomSelection(words) {
  let shuffled = shuffle(words.split("\n"));
  return shuffled.slice(1, Math.min(shuffled.length, 1000));
}

function httpGetAsync(url, callback) {
  let xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
      callback(xmlHttp.responseText);
  }
  xmlHttp.open('GET', url, true);
  xmlHttp.send(null);
}

function sendWordsToElm(url) {
  httpGetAsync(url, function (response) {
    app.ports.wordsFromJS.send(getRandomSelection(response));
  });
}
